import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { ReactElement } from "react";
import { StartupMainTabs } from "../../../../Types/Startup";

interface Props {
  activeTab: number;
  tabs: StartupMainTabs;
  handleChangeTab: (index: number) => void;
}

function PrimaryTab(props: Props): ReactElement {
  return (
    <Tabs
      value={props.activeTab}
      sx={{
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          gap: 3,
        },
      }}
    >
      {props.tabs.map((type, index) => {
        return (
          <Tab
            sx={{ pl: 0 }}
            key={index}
            onClick={() => props.handleChangeTab(index)}
            label={
              <Stack textAlign="start">
                <Typography variant="subtitle1">{type.title}</Typography>
                <Typography variant="overline" color="text.mediumEmphasis">
                  {type.description}
                </Typography>
              </Stack>
            }
          />
        );
      })}
    </Tabs>
  );
}

export default PrimaryTab;
