type ClientSpecificationsType = {
  logoWhite?: string;
  background?: string;
  logo?: string;
  hero?: string;
  loginPageLogo?: string;
  primaryColor?: string;
  secondaryColor?: string;
  accessibilityColor?: string;
  documentStyle?: string;
};

export const clientSpecifications: {
  [name: string]: ClientSpecificationsType;
} = {
  "27pilots": {
    background: "27pilots-munich-bg.png",
    hero: "27pilots.png",
    logo: "27pilots-logo.svg",
    logoWhite: "27pilots.svg",
    loginPageLogo: "27pilots-logo.svg",
  },
  localhost: {
    background: "27pilots-bg.png",
    hero: "27pilots.png",
    logo: "27pilots-logo.svg",
    logoWhite: "27pilots.svg",
    loginPageLogo: "27pilots-logo.svg",
  },
  "vclos-dev": {
    background: "27pilots-bg.png",
    hero: "27pilots.png",
    logo: "27pilots-logo.svg",
    logoWhite: "27pilots.svg",
    loginPageLogo: "27pilots-logo.svg",
  },
  company1: {
    background: "company1-bg.png",
    logo: "company1-logo.svg",
    logoWhite: "Company1.svg",
    loginPageLogo: "company1-loginPageLogo.svg",
    hero: "Company1.png",
  },
  company2: {
    background: "company2-bg.png",
    logo: "company2-logo.svg",
    logoWhite: "Company2.svg",
    loginPageLogo: "company2-loginPageLogo.svg",
    hero: "Company2.png",
  },
  company3: {
    background: "company3-bg.png",
    logo: "company3-logo.svg",
    logoWhite: "Company3.svg",
    loginPageLogo: "company3-loginPageLogo.svg",
    hero: "Company3.png",
  },
  "siemens-energy": {
    logoWhite: "SiemensEnergy.svg",
    logo: "siemensEnergy-logo.png",
    background: "siemensEnergy-bg.png",
    loginPageLogo: "siemensEnergy-loginPageLogo.svg",
    primaryColor: "#8A00E5",
    secondaryColor: "#ECD6FB",
    documentStyle: "Siemens Energy",
  },
  holcim: {
    logoWhite: "Holcim.svg",
    logo: "holcim-logo.png",
    background: "holcim-bg.webp",
    hero: "Holcim.png",
    loginPageLogo: "holcim-loginPageLogo.svg",
    primaryColor: "#1D4370",
    documentStyle: "Holcim",
  },
  cariad: {
    logoWhite: "Cariad.svg",
    logo: "cariad-logo.jpeg",
    background: "cariad-bg.JPEG",
    hero: "Cariad.png",
    loginPageLogo: "cariad-loginPageLogo.svg",
    primaryColor: "#442EE0",
    secondaryColor: "#E1DEFA",
    documentStyle: "CARIAD",
  },
  otto: {
    logoWhite: "OTTO.svg",
    logo: "otto-logo.svg",
    background: "otto-bg.jpg",
    hero: "OTTO.png",
    loginPageLogo: "otto-logo.svg",
    primaryColor: "#e50e2a",
    secondaryColor: "#FBD8DD",
    accessibilityColor: "#000000",
    documentStyle: "Otto",
  },
  bmwstartupgarage: {
    logoWhite: "BMW.svg",
    logo: "bmwstartupgarage-logo.png",
    background: "bmwstartupgarage-bg.jpg",
    hero: "BMW.png",
    loginPageLogo: "BMW-loginPageLogo.svg",
    primaryColor: "#1C69D4",
    secondaryColor: "#DBE7F8",
    documentStyle: "BMW",
  },
  obi: {
    logo: "obi-logo.png",
    background: "obi-bg.jpg",
  },
  airbus: {
    logoWhite: "Airbus.svg",
    logo: "airbus-logo.svg",
    background: "airbus-bg.jpg",
    hero: "Airbus.png",
    loginPageLogo: "airbus-logo.svg",
    primaryColor: "#071A3D",
    secondaryColor: "#D7DAE0",
    documentStyle: "Airbus",
  },
  openbosch: {
    logoWhite: "Bosch.svg",
    logo: "openbosch-logo.png",
    background: "openbosch-bg.jpg",
    hero: "OpenBosch.png",
    loginPageLogo: "openbosch-loginPageLogo.svg",
    primaryColor: "#A80062",
    secondaryColor: "#F1D6E6",
    documentStyle: "Bosch",
  },
  "siemens-mobility": {
    logoWhite: "Siemens.svg",
    logo: "stationx-logo.png",
    background: "stationx-bg.jpg",
    hero: "StationX.png",
    loginPageLogo: "Siemens-loginPageLogo.svg",
    primaryColor: "#009999",
    secondaryColor: "#D6F7F7",
    documentStyle: "Siemens Mobility",
  },
  ksb: {
    logoWhite: "KSB.svg",
    logo: "ksb-logo.png",
    background: "ksb-bg.jpg",
    hero: "KSB.png",
    documentStyle: "KSB",
  },
  copace: {
    logoWhite: "Continental.svg",
    logo: "copace-logo.png",
    background: "copace-bg.png",
  },
  mtu: {
    logoWhite: "MTU.svg",
    logo: "mtu-logo.png",
    background: "mtu-bg.jpg",
    hero: "MTU.png",
    loginPageLogo: "MTU-loginPageLogo.svg",
    primaryColor: "#003963",
    secondaryColor: "#D6DFE6",
    documentStyle: "MTU",
  },
  knauf: {
    logoWhite: "Knauf.svg",
    logo: "knauf-logo.png",
    background: "knauf-bg.png",
    hero: "Knauf.png",
    loginPageLogo: "knauf-loginPageLogo.svg",
    primaryColor: "#0099DE",
    secondaryColor: "#D6EFFA",
    documentStyle: "Knauf",
  },
  vodafone: {
    logo: "vodafone-logo.png",
    background: "vodafone-bg.jpeg",
  },
  obr: {
    logo: "obr-logo.png",
    logoWhite: "Bosch.svg",
    background: "obr-bg.jpeg",
    hero: "OpenBosch+Research.png",
    documentStyle: "Bosch Corporate Research",
  },
  "siemens-di": {
    logoWhite: "Siemens.svg",
    logo: "siemens-di-logo.png",
    background: "siemens-di-bg.jpg",
    documentStyle: "Siemens Digital Industries",
  },
  prosiebensat1: {
    logo: "prosiebensat1-logo.png",
    background: "prosiebensat1-bg.jpg",
  },
  forvia: {
    logoWhite: "Forvia.svg",
    logo: "forvia-logo.png",
    background: "forvia-bg.jpg",
    hero: "Forvia.png",
    loginPageLogo: "forvia-loginPageLogo.svg",
    primaryColor: "#0A23CA",
    secondaryColor: "#D8DCF7",
  },
  equinor: {
    logoWhite: "Equinor.svg",
    logo: "equinor-logo.png",
    background: "equinor-bg.webp",
    hero: "Equinor.png",
    loginPageLogo: "equinor-loginPageLogo.svg",
    primaryColor: "#005157",
    secondaryColor: "#D6E3E4",
    documentStyle: "Equinor",
  },
  axa: {
    logoWhite: "AXA.svg",
    logo: "axa-logo.png",
    background: "axa-bg.jpg",
    loginPageLogo: "axa-loginPageLogo.svg",
    primaryColor: "#00008F",
    secondaryColor: "#D6D6ED",
    documentStyle: "AXA",
  },
  seatcode: {
    logoWhite: "SeatCode.svg",
    logo: "seatCode-logo.png",
    background: "seatcode-bg.png",
    hero: "SeatCode.png",
    loginPageLogo: "seatcode-loginPageLogo.svg",
    primaryColor: "#A7F9AB",
    secondaryColor: "#E3FDE4",
    documentStyle: "SEAT",
  },
  "zurich-insurance": {
    logoWhite: "Zurich.svg",
    logo: "zurichinsurance-logo.png",
    background: "zurichinsurance-bg.jpg",
    loginPageLogo: "zurichinsurance-loginPageLogo.svg",
    primaryColor: "#2167AE",
    secondaryColor: "#DBE7F2",
    documentStyle: "Zurich Insurance",
  },
  govtechh: {
    logoWhite: "GovTecHH.svg",
    logo: "govtechh-logo.png",
    background: "govtechh-bg.jpg",
    hero: "GovTecHH.png",
    loginPageLogo: "GovTecHH-loginPageLogo.png",
    primaryColor: "#003063",
    secondaryColor: "#D6DEE6",
  },
  lbbw: {
    logoWhite: "LBBW.svg",
    logo: "lbbw-logo.svg",
    background: "lbbw-bg.jpg",
    hero: "LBBW.png",
    documentStyle: "LBBW",
  },
  beyondgravity: {
    logo: "beyondgravity-logo.png",
    background: "beyondgravity-bg.jpg",
  },
  redbull: {
    logoWhite: "Redbull.svg",
    logo: "redbull-logo.png",
    background: "redbull-bg.png",
  },
  kaufland: {
    logo: "kaufland-logo.jpg",
    background: "kaufland-bg.jpg",
  },
  vinci: {
    logo: "vinci-logo.png",
    background: "vinci-bg.jpg",
  },
  helm: {
    logoWhite: "Helm.svg",
    logo: "helm-logo.svg",
    background: "helm-bg.jpg",
    primaryColor: "#90005D",
    secondaryColor: "#EDD6E5",
    hero: "Helm.png",
    loginPageLogo: "helm-loginPageLogo.png",
    documentStyle: "HELM",
  },
  siemenssi: {
    logoWhite: "Siemens.svg",
    logo: "siemenssi-logo.png",
    background: "siemenssi-bg.jpg",
    loginPageLogo: "Siemens-loginPageLogo.svg",
  },
  miele: {
    logoWhite: "Miele.svg",
    logo: "miele-logo.png",
    background: "miele-bg.png",
    hero: "Miele.png",
    loginPageLogo: "miele-loginPageLogo.svg",
    primaryColor: "#8C0014",
    secondaryColor: "#EDD6D9",
    documentStyle: "Miele",
  },
  sportradar: {
    logoWhite: "Sportradar.svg",
    logo: "sportradar-logo.png",
    background: "sportradar-bg.jpeg",
    hero: "Sportradar.png",
    loginPageLogo: "sportradar-logo.png",
    primaryColor: "#00003C",
    secondaryColor: "#D6D6E0",
    documentStyle: "Sportradar",
  },
  gd: {
    logoWhite: "GD_GieseckeDerivent.svg",
    logo: "gd-loginPageLogo.svg",
    background: "gd-bg.png",
    loginPageLogo: "gd-loginPageLogo.svg",
    hero: "GD.png",
    primaryColor: "#040066",
    secondaryColor: "#D7D6E7",
  },
  trumpf: {
    logoWhite: "Trumpf.svg",
    logo: "trumpf.svg",
    background: "trumpf-bg.png",
    loginPageLogo: "trumpf-loginPageLogo.svg",
    hero: "Trumpf.png",
    primaryColor: "#0033BA",
    secondaryColor: "#D6DEF4",
  },
  provinzial: {
    logoWhite: "Provinzial.svg",
    logo: "provinzial.svg",
    background: "provinzial-bg.png",
    loginPageLogo: "provinzial-loginPageLogo.svg",
    hero: "Provinzial.png",
    primaryColor: "#005940",
    secondaryColor: "#D6E4E0",
  },
  bbraun: {
    logoWhite: "Bbraun.svg",
    logo: "bbraun-logo.svg",
    background: "bbraun-bg.png",
    loginPageLogo: "bbraun-loginPageLogo.svg",
    hero: "Bbraun.png",
    primaryColor: "#00A97A",
    secondaryColor: "#D6F1EA",
  },
  abb: {
    logoWhite: "ABB.svg",
    logo: "ABB-logo.svg",
    background: "ABB-bg.jpg",
    loginPageLogo: "ABB-loginPageLogo.png",
    hero: "ABB.jpg",
    primaryColor: "#FF000F",
    secondaryColor: "#FFD6D9",
  },
};
