import { Box, Stack, Typography, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../../theme";
import { Project } from "../../../../../Types/Project";
import CustomExpendableText from "../../../../UI/CustomExpendableText";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";

const StyledAgendaContainer = styled(Stack)(() => ({
  display: "flex",
  background: theme.palette.surface.secondary.light,
  borderRadius: theme.shape.radius.cardLarge,
  padding: theme.spacing(3),
  gap: theme.spacing(5),
  width: "100%",
}));

interface ProductDemosAgendaProps {
  project: Project;
  editMode: boolean;
  setDemoUseCases: (value: string) => void;
  setDemoQuestions: (value: string) => void;
}

export default function ProductDemosAgenda(
  props: ProductDemosAgendaProps
): ReactElement {
  return (
    <StyledAgendaContainer>
      {!props.editMode ? (
        <>
          <Box display="flex" flexDirection="column" gap={theme.spacing(0.5)}>
            <Typography variant="caption" color="text.mediumEmphasis">
              Use Cases
            </Typography>
            <CustomExpendableText text={props.project.demoUseCases || "--"} />
          </Box>
          <Box display="flex" flexDirection="column" gap={theme.spacing(0.5)}>
            <Typography variant="caption" color="text.mediumEmphasis">
              General Questions for All Startups
            </Typography>
            <CustomExpendableText text={props.project.demoQuestions || "--"} />
          </Box>
        </>
      ) : (
        <>
          <CustomTextField
            fullWidth
            multiline
            rows={5}
            defaultValue={props.project.demoUseCases}
            onChange={(e) => props.setDemoUseCases(e.target.value)}
            label="Use Cases"
            editMode
            data-testid="use-cases"
          />
          <CustomTextField
            multiline
            rows={5}
            defaultValue={props.project.demoQuestions}
            onChange={(e) => props.setDemoQuestions(e.target.value)}
            label="General Questions for All Startups"
            editMode
            data-testid="questions"
          />
        </>
      )}
    </StyledAgendaContainer>
  );
}
