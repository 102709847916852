import { Box, DialogContent, DialogContentProps, styled } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import theme from "../../../theme";

const TopGradient = styled(Box)({
  position: "sticky",
  top: 0,
  width: "100%",
  height: theme.spacing(6),
  zIndex: 1,
  background:
    "linear-gradient(180deg, #FFF 6.64%, rgba(255, 255, 255, 0.00) 93.34%)",
  marginTop: theme.spacing(-5),
  transition: "opacity 0.2s ease",
  pointerEvents: "none",
});

const BottomGradient = styled(TopGradient)({
  top: undefined,
  bottom: 0,
  background:
    "linear-gradient(0deg, #FFF 6.64%, rgba(255, 255, 255, 0.00) 93.34%)",
});

const ScrollableDialogContent = (props: DialogContentProps): ReactElement => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [topVisible, setTopVisible] = useState(
    dialogRef.current && dialogRef.current?.scrollTop > 0
  );
  const [bottomVisible, setBottomVisible] = useState(
    dialogRef.current &&
      dialogRef.current.scrollHeight > dialogRef.current.offsetHeight
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!dialogRef.current) return;

      const scrollableHeight =
        dialogRef.current.scrollHeight - dialogRef.current.offsetHeight;

      if (dialogRef.current?.scrollTop > 0) {
        setTopVisible(true);
      } else if (dialogRef.current?.scrollTop === 0) setTopVisible(false);

      if (
        dialogRef.current?.scrollTop > 0 &&
        dialogRef.current.scrollTop < scrollableHeight
      ) {
        setBottomVisible(true);
      } else if (dialogRef.current?.scrollTop >= scrollableHeight) {
        setBottomVisible(false);
      }
    };

    dialogRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      dialogRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [dialogRef.current]);

  return (
    <DialogContent
      {...props}
      ref={dialogRef}
      sx={{ overflow: "scroll", ...props.sx }}
    >
      <TopGradient sx={{ opacity: topVisible ? 1 : 0 }} />
      {props.children}
      <BottomGradient sx={{ opacity: bottomVisible ? 1 : 0 }} />
    </DialogContent>
  );
};

export default ScrollableDialogContent;
