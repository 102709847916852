import { createTheme } from "@mui/material/styles";
import primitives from "./theme-primitives.json";
import { ColorPartial } from "@mui/material/styles/createPalette";
import { tooltipClasses } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { extractClientPrefixFromUrl, lightenHexColor } from "./utils";
import { clientSpecifications } from "./Constants/ClientSpecifications";

const white = "#FFFFFF";
const black = "#000000";
let brand = primitives.green[500];
let brandAccessibility = primitives.green[800];

const clientPrefix = extractClientPrefixFromUrl();

if (clientPrefix) {
  const { primaryColor, secondaryColor, accessibilityColor } =
    clientSpecifications[clientPrefix];

  if (primaryColor) {
    primitives.blue[500] = primaryColor;
    primitives.blue[600] = lightenHexColor(primaryColor, 20);
    primitives.blue[800] = lightenHexColor(primaryColor, -40);
    primitives.green[500] = lightenHexColor(primaryColor, -40);
    brand = primaryColor;
    brandAccessibility = accessibilityColor || primaryColor;
  }

  if (secondaryColor) {
    primitives.blue[100] = secondaryColor;
    primitives.blue[50] = lightenHexColor(secondaryColor, 20);
    primitives.blue[200] = lightenHexColor(secondaryColor, 10);
    primitives.blue[300] = lightenHexColor(secondaryColor, -20);
  }
}

declare module "@mui/material/styles" {
  interface TypeText {
    action: {
      main: string;
      secondary: string;
      hover: string;
    };
    brand: {
      main: string;
      accessibility: string;
    };
    highEmphasis: string;
    mediumEmphasis: string;
    primaryInvert: {
      main: string;
      highEmphasis: string;
      mediumEmphasis: string;
      disabled: string;
    };
    error: string;
    alert: string;
    success: string;
    tertiaryDark: string;
  }

  interface TypeSurface {
    primary: {
      main: string;
      invert: string;
    };
    secondary: {
      main: string;
      light: string;
    };
    tertiary: {
      light: string;
      dark: string;
    };
    action: {
      main: string;
      secondary: string;
    };
    brand: {
      main: string;
      accessibility: string;
    };
    cool: string;
    medium: string;
    highEmphasis: string;
    mediumEmphasis: string;
    lowEmphasis: string;
    lightEmphasis: string;
    disabled: string;
    error: string;
    alert: string;
    success: string;
  }

  interface TypeChart {
    dark: {
      primary: string;
      secondary: string;
    };
    medium: {
      primary: string;
      secondary: string;
    };
    light: {
      primary: string;
      secondary: string;
    };
    soft: {
      primary: string;
      secondary: string;
    };
  }

  interface Theme {
    shape: Shape;
    boxShadows: BoxShadows;
  }

  interface ThemeOptions {
    shape?: Shape;
    boxShadows?: BoxShadows;
  }

  interface TypeAction {
    main: string;
    secondary: string;
  }

  type BoxShadows = [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];

  interface Shape {
    borderRadius: number;
    radius: {
      minimal: string;
      regular: string;
      rounded: string;
      full: string;
      cardSmall: string;
      cardMedium: string;
      cardLarge: string;
    };
  }

  interface ShapeOptions {
    borderRadius?: number;
    radius?: {
      minimal?: string;
      regular?: string;
      rounded?: string;
      full?: string;
      cardSmall?: string;
      cardMedium?: string;
      cardLarge?: string;
    };
  }

  interface Palette {
    brand: {
      main: string;
      accessibility: string;
    };
    borderOutline: { main: string; soft: string; focus: string };
    icon: TypeText;
    surface: TypeSurface;
    chart: TypeChart;
  }

  interface PaletteOptions {
    brand?: {
      main?: string;
      accessibility?: string;
    };
    borderOutline?: { main?: string; soft?: string; focus?: string };
    icon?: Partial<TypeText>;
    surface?: Partial<TypeSurface>;
    chart?: Partial<TypeChart>;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorPartial {}
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    choice: true;
    counter: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    textInvert: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: "-0.008125em",
    },
    h2: {
      fontSize: "1.8125rem",
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: "-0.00866em",
    },
    h3: {
      fontSize: "1.625rem",
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: "-0.01em",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: "-0.01182em",
    },
    h5: {
      fontSize: "1.1875rem",
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: "-0.02368em",
    },
    h6: {
      fontSize: "1.0625rem",
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: "-0.02529em",
    },
    subtitle1: {
      fontSize: "0.9375rem",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "-0.0153em",
    },
    subtitle2: {
      fontSize: "0.8125rem",
      fontWeight: 600,
      lineHeight: 1.53847,
      letterSpacing: "-0.00615em",
    },
    body1: {
      fontSize: "0.8125rem",
      fontWeight: 400,
      lineHeight: 1.53847,
      letterSpacing: "-0.00615em",
    },
    body2: {
      fontSize: "0.6875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00545em",
    },
    button: {
      fontSize: "0.8125rem",
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: "-0.00615em",
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0em",
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0em",
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: primitives.green[500],
      ...primitives.green,
    },
    secondary: {
      main: primitives.blue[500],
      ...primitives.blue,
    },
    success: {
      main: primitives.success,
      light: primitives.success + primitives.transparent.emphasisLight,
    },
    error: {
      main: primitives.red,
    },
    warning: {
      main: primitives.orange,
    },
    background: {
      default: primitives.gray.soft,
    },
    borderOutline: {
      main: primitives.gray.cool,
      soft: primitives.gray.soft,
      focus: primitives.blue[200],
    },
    brand: {
      main: brand,
      accessibility: brandAccessibility,
    },
    // Text
    text: {
      primary: black,
      action: {
        main: primitives.blue[500],
        secondary: primitives.blue[100],
        hover: primitives.blue[300],
      },
      brand: {
        main: brand,
        accessibility: brandAccessibility,
      },
      highEmphasis: black + primitives.transparent.emphasisHigh,
      mediumEmphasis: black + primitives.transparent.emphasisMedium,
      primaryInvert: {
        main: white,
        highEmphasis: white + primitives.transparent.emphasisHigh,
        mediumEmphasis: white + primitives.transparent.emphasisMedium,
        disabled: white + primitives.transparent.disabled,
      },
      error: primitives.red,
      alert: primitives.orange,
      disabled: black + primitives.transparent.disabled,
    },
    // Icons
    icon: {
      primary: black,
      action: {
        main: primitives.blue[500],
        secondary: primitives.blue[100],
        hover: primitives.blue[300],
      },
      brand: {
        main: brand,
        accessibility: brandAccessibility,
      },
      highEmphasis: black + primitives.transparent.emphasisHigh,
      mediumEmphasis: black + primitives.transparent.emphasisMedium,
      primaryInvert: {
        main: white,
        highEmphasis: white + primitives.transparent.emphasisHigh,
        mediumEmphasis: white + primitives.transparent.emphasisMedium,
        disabled: white + primitives.transparent.disabled,
      },
      error: primitives.red,
      alert: primitives.orange,
      success: primitives.success,
      disabled: black + primitives.transparent.disabled,
      tertiaryDark: primitives.blue[800],
    },
    // Surface
    surface: {
      primary: {
        main: white,
        invert: black,
      },
      secondary: {
        main: primitives.gray.soft,
        light: primitives.gray.light,
      },
      tertiary: {
        light: primitives.blue[50],
        dark: primitives.blue[800],
      },
      brand: {
        main: brand,
        accessibility: brandAccessibility,
      },
      action: {
        main: primitives.blue[500],
        secondary: primitives.blue[100],
      },
      cool: primitives.gray.cool,
      medium: primitives.gray.medium,
      disabled: primitives.blue[500] + primitives.transparent.disabled,
      highEmphasis: primitives.blue[500] + primitives.transparent.emphasisHigh,
      mediumEmphasis:
        primitives.blue[500] + primitives.transparent.emphasisMedium,
      lowEmphasis: primitives.blue[500] + primitives.transparent.emphasisLow,
      lightEmphasis:
        primitives.blue[500] + primitives.transparent.emphasisLight,
      error: primitives.red,
      alert: primitives.orange,
      success: primitives.success,
    },
    chart: {
      dark: {
        primary: primitives.teal[900],
        secondary: primitives.teal[800],
      },
      medium: {
        primary: primitives.teal[700],
        secondary: primitives.teal[600],
      },
      light: {
        primary: primitives.teal[500],
        secondary: primitives.teal[400],
      },
      soft: {
        primary: primitives.teal[300],
        secondary: primitives.teal[200],
      },
    },
  },
  shape: {
    borderRadius: 4,
    radius: {
      minimal: "4px",
      regular: "8px",
      rounded: "16px",
      full: "256px",
      cardSmall: "4px",
      cardMedium: "4px",
      cardLarge: "4px",
    },
  },
  boxShadows: [
    "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
    "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
    "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    "0px 6px 12px 0px rgba(0, 0, 0, 0.12)",
    "none",
    "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "0px 8px 20px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "none",
    "none",
    "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "none",
    "none",
    "0px 4px 32px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "0px 4px 40px 0px rgba(0, 0, 0, 0.08)",
  ],
  shadows: [
    "none",
    "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
    "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
    "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    "0px 6px 12px 0px rgba(0, 0, 0, 0.12)",
    "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "0px 8px 20px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "none",
    "none",
    "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "none",
    "none",
    "0px 4px 32px 0px rgba(0, 0, 0, 0.08)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "0px 4px 40px 0px rgba(0, 0, 0, 0.08)",
  ],
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: () => ({
          "&.Mui-disabled": {
            backgroundColor: theme.palette.surface.primary.main,
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: () => ({
          padding: theme.spacing(0, 3, 3),
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: () => ({
          "&.Mui-expanded": {
            margin: 0,
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: () => ({
          "&.PrivateSwitchBase-root:hover": {
            backgroundColor:
              theme.palette.surface.action.main +
              primitives.transparent.emphasisLight,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: "fit-content",
          minHeight: theme.spacing(4.5),
          borderRadius: theme.shape.radius.minimal,
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: theme.palette.surface.action.main,
              color: white,
              "&:hover": {
                backgroundColor: primitives.blue[600],
              },
              "&:active": {
                backgroundColor: primitives.blue[800],
              },
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
              backgroundColor: theme.palette.surface.action.secondary,
              color: theme.palette.text.action.main,
              "&:hover": {
                backgroundColor: primitives.blue[50],
              },
              "&:active": {
                backgroundColor: primitives.blue[300],
              },
            }),
          ...(ownerState.variant === "outlined" && {
            borderColor: theme.palette.borderOutline.main,
            color: theme.palette.text.action.main,
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: primitives.blue[50],
              borderColor: theme.palette.borderOutline.main,
            },
            "&:active": {
              backgroundColor: primitives.blue[100],
            },
          }),
          ...(ownerState.variant === "text" && {
            borderColor: theme.palette.borderOutline.main,
            color: theme.palette.text.action.main,
            "&:hover": {
              backgroundColor: primitives.blue[50],
            },
            "&:active": {
              backgroundColor: primitives.blue[100],
            },
          }),
          ...(ownerState.variant === "textInvert" && {
            borderColor: theme.palette.borderOutline.main,
            color: theme.palette.text.primaryInvert.main,
            "&:hover": {
              color: theme.palette.text.action.main,
              backgroundColor: primitives.blue[50],
            },
            "&:active": {
              color: theme.palette.text.action.main,
              backgroundColor: primitives.blue[100],
            },
          }),
          ":disabled": {
            color:
              ownerState.variant === "textInvert"
                ? theme.palette.text.primaryInvert.disabled
                : theme.palette.text.disabled,
            backgroundColor:
              ownerState.variant === "contained"
                ? theme.palette.background.default
                : "transparent",
          },
          "&:focus": {
            outline: `2px solid ${theme.palette.borderOutline.focus}`,
            borderColor: "transparent",
          },
          minWidth: ownerState.variant === "text" ? "none" : theme.spacing(16),
          padding: theme.spacing(1),
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === "secondary" && {
            backgroundColor: theme.palette.surface.action.secondary,
            color: theme.palette.text.action.main,
            "&:hover": {
              backgroundColor: primitives.blue[50],
            },
            "&:active": {
              backgroundColor: primitives.blue[300],
            },
            ":disabled": {
              color: theme.palette.text.disabled,
              backgroundColor:
                ownerState.variant === "contained"
                  ? theme.palette.background.default
                  : "transparent",
            },
            "&:focus": {
              outline: `2px solid ${theme.palette.borderOutline.focus}`,
              borderColor: "transparent",
            },
          }),
          ...(ownerState.color === "primary" && {
            backgroundColor: theme.palette.surface.action.main,
            color: theme.palette.text.primaryInvert.main,
            "&:hover": {
              backgroundColor: primitives.blue[600],
            },
            "&:active": {
              backgroundColor: primitives.blue[800],
            },
            ":disabled": {
              color: theme.palette.text.disabled,
              backgroundColor:
                ownerState.variant === "contained"
                  ? theme.palette.background.default
                  : "transparent",
            },
            "&:focus": {
              outline: `2px solid ${theme.palette.borderOutline.focus}`,
              borderColor: "transparent",
            },
          }),
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: () => ({
          color: theme.palette.text.action.main,
          ":hover": {
            color: theme.palette.text.action.hover,
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: () => ({
          ".MuiDialog-paper": {
            padding: theme.spacing(4),
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: () => ({
          padding: 0,
          overflow: "visible",
          ":first-of-type": {
            marginTop: 0,
          },
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: () => ({
          color: black + primitives.transparent.emphasisMedium,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: theme.spacing(1),
          ...theme.typography.h3,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: () => ({
          padding: 0,
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: ownerState.disabled
            ? black + primitives.transparent.disabled
            : black,
          "&.Mui-checked, &.MuiCheckbox-indeterminate": {
            color: ownerState.disabled
              ? theme.palette.surface.action.main +
                primitives.transparent.disabled
              : theme.palette.surface.action.main,
          },
          "&:hover": {
            backgroundColor:
              theme.palette.surface.action.main +
              primitives.transparent.emphasisLight,
          },
          "&.Mui-focusVisible": {
            outline: `2px solid ${primitives.blue[200]}`,
            backgroundColor:
              theme.palette.surface.action.main +
              primitives.transparent.emphasisLight,
          },
          "& .MuiTouchRipple-root": {
            color: theme.palette.surface.action.main,
          },
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: ownerState.disabled
            ? black + primitives.transparent.disabled
            : black,
          "&.Mui-checked": {
            color: ownerState.disabled
              ? theme.palette.surface.action.main +
                primitives.transparent.disabled
              : theme.palette.surface.action.main,
          },
          "&:hover": {
            backgroundColor:
              theme.palette.surface.action.main +
              primitives.transparent.emphasisLight,
          },
          "&.Mui-focusVisible": {
            outline: `2px solid ${primitives.blue[200]}`,
            backgroundColor:
              theme.palette.surface.action.main +
              primitives.transparent.emphasisLight,
          },
          "& .MuiTouchRipple-root": {
            color: theme.palette.surface.action.main,
          },
        }),
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: () => ({
          overflow: "visible",
        }),
        colorPrimary: ({ ownerState }) => ({
          color: theme.palette.surface.action.main,
          "&:not(.Mui-checked)": {
            "&+.MuiSwitch-track": {
              backgroundColor: theme.palette.surface.cool,
              opacity: 1,
            },
            "& .MuiSwitch-thumb": {
              color: ownerState.disabled
                ? theme.palette.background.default
                : theme.palette.surface.primary.main,
            },
          },
          "&.Mui-checked": {
            color: theme.palette.surface.action.main,
            "&+.MuiSwitch-track": {
              backgroundColor: theme.palette.surface.action.secondary,
              "&.Mui-disabled": {
                backgroundColor: theme.palette.surface.lowEmphasis,
              },
              opacity: 1,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: theme.palette.surface.action.secondary,
            },
            "&:hover": {
              backgroundColor:
                theme.palette.surface.action.main +
                primitives.transparent.emphasisLight,
            },
          },
          "&.Mui-focusVisible": {
            outline: `2px solid ${primitives.blue[200]}`,
            backgroundColor:
              theme.palette.surface.action.main +
              primitives.transparent.emphasisLight,
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        slotProps: {
          popper: {
            sx: () => ({
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: theme.spacing(2),
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: theme.spacing(2),
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                {
                  marginLeft: theme.spacing(2),
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                {
                  marginRight: theme.spacing(2),
                },
            }),
          },
        },
      },
      styleOverrides: {
        tooltip: () => ({
          backgroundColor: theme.palette.icon.highEmphasis,
          padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: (ownerState) => ({
          ...(ownerState.variant === "outlined" && {
            "& .MuiOutlinedInput-root": {
              background: theme.palette.surface.primary.main,
            },
          }),
          ...(ownerState.variant === "outlined" &&
            !ownerState.disabled &&
            !ownerState.error && {
              "& .MuiInputLabel-root": {
                color: black,
                "&.MuiInputLabel-shrink": {
                  color: theme.palette.text.mediumEmphasis,
                },
              },
              "& .MuiOutlinedInput-root": {
                background: theme.palette.surface.primary.main,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.borderOutline.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.borderOutline.focus,
                },
              },
              "& .MuiFormHelperText-root": {
                color: theme.palette.text.disabled,
              },
            }),
          ...(!ownerState.disabled && {
            "& .MuiSvgIcon-root": {
              color: theme.palette.icon.mediumEmphasis,
            },
          }),
          ...(ownerState.error === true && {
            "& .MuiInputLabel-root": {
              color: theme.palette.text.alert,
              "&.MuiInputLabel-shrink": {
                color: theme.palette.text.alert,
              },
            },
            "& .MuiFormLabel-asterisk": {
              color: theme.palette.text.alert,
            },

            "& .MuiFormHelperText-root.Mui-error": {
              color: theme.palette.text.alert,
            },

            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.surface.alert,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.surface.alert,
              },
            },
          }),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          "&.MuiMenuItem-root": {
            backgroundColor: white,
            border: "2px solid transparent", //to avoid jumps when item is focused
            "&:focus": {
              backgroundColor: white,
              border: `2px solid ${theme.palette.borderOutline.focus}`,
              borderRadius: theme.shape.radius.cardSmall,
            },
            "&:hover, &:active": {
              backgroundColor: theme.palette.surface.action.secondary,
              color: theme.palette.text.action.main,
              "& .MuiSvgIcon-root": {
                color: theme.palette.text.action.main,
              },
            },
            "&.Mui-disabled": {
              opacity: 1,
              color: theme.palette.text.disabled,
              "& .MuiSvgIcon-root": {
                color: theme.palette.icon.disabled,
              },
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.icon.mediumEmphasis,
            },
            "&[aria-selected='true']": {
              backgroundColor: theme.palette.surface.lightEmphasis,
              color: theme.palette.text.action.main,
              "&:hover": {
                backgroundColor: theme.palette.surface.action.secondary,
              },
            },
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: () => ({
          padding: 0,
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
      variants: [
        {
          props: { variant: "choice" },
          style: () => ({
            color: theme.palette.text.action.main,
            backgroundColor: theme.palette.surface.action.secondary,
            "&.Mui-disabled": {
              backgroundColor: theme.palette.surface.secondary.main,
            },
          }),
        },
        {
          props: { variant: "outlined" },
          style: () => ({
            borderColor: theme.palette.borderOutline.main,
            color: theme.palette.text.primaryInvert.main,
            backgroundColor: "transparent",
            "&.Mui-disabled": {
              color: theme.palette.text.primaryInvert.disabled,
              borderColor: theme.palette.borderOutline.soft,
            },
          }),
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: () => ({
            borderColor: theme.palette.borderOutline.main,
            color: theme.palette.text.primary,
            backgroundColor: "transparent",
            "&.Mui-disabled": {
              color: theme.palette.text.disabled,
              borderColor: theme.palette.borderOutline.soft,
            },
          }),
        },
        {
          props: { variant: "counter" },
          style: () => ({
            minWidth: theme.spacing(4),
            color: theme.palette.text.primaryInvert.main,
          }),
        },
        {
          props: { variant: "counter", color: "primary" },
          style: () => ({
            backgroundColor: theme.palette.surface.action.main,
          }),
        },
        {
          props: { variant: "counter", color: "secondary" },
          style: () => ({
            minWidth: theme.spacing(5),
            backgroundColor: brand,
          }),
        },
        {
          props: { variant: "counter", color: "info" },
          style: () => ({
            backgroundColor: black,
          }),
        },
        {
          props: { variant: "counter", color: "info" },
          style: () => ({
            backgroundColor: black,
          }),
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: theme.palette.surface.secondary.main,
          maxWidth: "fit-content",
          "&:focus": {
            outline: `2px solid ${theme.palette.borderOutline.focus}`,
          },
          "&:active": {
            ...(ownerState.clickable && {
              backgroundColor: theme.palette.surface.secondary.light,
            }),
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            opacity: 1,
            color: theme.palette.text.primary + primitives.transparent.disabled,
            "& .MuiSvgIcon-root": {
              color:
                theme.palette.icon.primary + primitives.transparent.disabled,
            },
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
          "&.MuiChip-sizeSmall": {
            height: theme.spacing(2.25),
          },
          ...theme.typography.caption,
        }),
        icon: () => ({
          fontSize: 20,
          color: theme.palette.icon.primary,
        }),
        deleteIcon: () => ({
          fontSize: 18,
          color: theme.palette.icon.primary,
        }),
        sizeMedium: ({ ownerState }) => ({
          ...((ownerState.variant === "choice" ||
            ownerState.variant === "outlined") && {
            height: theme.spacing(3),
            ...theme.typography.caption,
          }),
        }),
        sizeSmall: ({ ownerState }) => ({
          ...((ownerState.variant === "choice" ||
            ownerState.variant === "outlined") && {
            height: theme.spacing(2.875),
            ...theme.typography.overline,
          }),
        }),
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: () => ({
          // Autocomplete dropdown
          "&.MuiAutocomplete-paper": {
            boxShadow: theme.boxShadows[24],
          },
          "& .MuiAutocomplete-listbox": {
            padding: 0,
            "& .MuiAutocomplete-option": {
              backgroundColor: white,
              border: "2px solid transparent",
              "&:hover, &:active": {
                backgroundColor: theme.palette.surface.action.secondary,
                color: theme.palette.text.action.main,
                "& .MuiSvgIcon-root": {
                  color: theme.palette.text.action.main,
                },
              },
              "&.Mui-focusVisible": {
                backgroundColor: white,
                border: `2px solid ${theme.palette.borderOutline.focus}`,
                borderRadius: theme.shape.radius.cardSmall,
                "&:hover": {
                  backgroundColor: theme.palette.surface.action.secondary,
                },
              },
              "&[aria-selected='true']": {
                backgroundColor: theme.palette.surface.lightEmphasis,
                color: theme.palette.text.action.main,
                "&.Mui-focused": {
                  backgroundColor: theme.palette.surface.lightEmphasis,
                  "&:hover": {
                    backgroundColor: theme.palette.surface.action.secondary,
                  },
                },
              },
            },
            "& .Mui-focused.MuiAutocomplete-option[aria-selected='true']": {
              borderRadius: theme.shape.radius.cardSmall,
              border: `2px solid ${theme.palette.borderOutline.focus}`,
            },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: () => ({
          borderBottom: `1px solid ${theme.palette.borderOutline.main}`,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.text.primary,
          "&.Mui-selected": {
            color: theme.palette.text.primary,
          },
          "& .MuiTouchRipple-root": {
            color: black + primitives.transparent.emphasisLow,
          },
          ...theme.typography.subtitle1,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: () => ({
          "&[aria-expanded=true] ~ fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.borderOutline.focus,
          },
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: () => ({
          "&.Mui-selected": {
            color: theme.palette.text.primaryInvert.main,
            backgroundColor: theme.palette.surface.brand.accessibility,
            "&:focus": {
              backgroundColor: primitives.green[900],
            },
            "&:hover": {
              backgroundColor: primitives.green[900],
            },
          },
        }),
        today: () => ({
          "&:not(.Mui-selected)": {
            border: `1px solid ${theme.palette.text.brand.main}`,
          },
        }),
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { color: "disabled" },
          style: () => ({
            color: theme.palette.icon.disabled,
          }),
        },
        {
          props: { color: "error" },
          style: () => ({
            color: theme.palette.icon.error,
          }),
        },
        {
          props: { color: "success" },
          style: () => ({
            color: theme.palette.icon.success,
          }),
        },
      ],
    },
    PrivatePickersYear: {
      styleOverrides: {
        root: () => ({
          "& .PrivatePickersYear-yearButton": {
            ...theme.typography.body1,
            "&.Mui-selected": {
              color: theme.palette.text.primaryInvert.main,
              backgroundColor: theme.palette.surface.brand.accessibility,
              "&:focus": {
                backgroundColor: primitives.green[900],
              },
              "&:hover": {
                backgroundColor: primitives.green[900],
              },
            },
          },
        }),
      },
    },
    PrivatePickersMonth: {
      styleOverrides: {
        root: () => ({
          "& .PrivatePickersMonth-monthButton": {
            ...theme.typography.body1,
            "&.Mui-selected": {
              color: theme.palette.text.primaryInvert.main,
              backgroundColor: theme.palette.surface.brand.accessibility,
              "&:focus": {
                backgroundColor: primitives.green[900],
              },
              "&:hover": {
                backgroundColor: primitives.green[900],
              },
            },
          },
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "&.MuiContainer-maxWidthLg": {
            maxWidth: "1648px",
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: () => ({
          transform: "none",
          borderRadius: theme.shape.radius.minimal,
          background: `linear-gradient(303deg, ${theme.palette.surface.secondary.light} -3.61%, ${theme.palette.borderOutline.main} 159.13%)`,
        }),
      },
    },
  },
});

export default theme;
