import axios from "axios";
import {
  CreateStartupDTO,
  GetStartupsParams,
  Startup,
  StartupDTO,
  StartupFilterCriteria,
  StartupPrimaryTabItems,
  StartupSalesforce,
  StartupSecondaryTabItems,
} from "../../Types/Startup";
import StartupMapper from "./Startup.mapper";
import { PaginationResult } from "../../Types/Common";

export class StartupHttpService {
  public static async getStartups(
    filterCriteria: StartupFilterCriteria,
    page: number,
    name: string,
    selectedPrimaryTab: StartupPrimaryTabItems,
    selectedSecondryTab: StartupSecondaryTabItems
  ): Promise<PaginationResult<Startup>> {
    const params: GetStartupsParams = {
      page: page,
      primaryTab: selectedPrimaryTab,
      secondaryTab: selectedSecondryTab,
    };

    if (Object.keys(filterCriteria).length != 0) {
      params.filterCriteria = JSON.stringify(filterCriteria);
    }
    if (name) {
      params.name = name;
    }
    const response = await axios.get<PaginationResult<StartupDTO>>(
      "/api/startups",
      { params: params }
    );

    const startups: { [key: number]: Startup[] } = {};

    Object.keys(response.data.data).forEach((key) => {
      startups[+key] = response.data.data[+key].map((startup: StartupDTO) =>
        StartupMapper.map(startup)
      );
    });

    return { ...response.data, data: startups };
  }

  public static async getStartupsByName(
    name: string,
    ignoreClient?: boolean
  ): Promise<Startup[]> {
    const response = await axios.get<StartupDTO[]>(`/api/startups`, {
      params: { name, ignoreClient },
    });

    return response.data.map((startup) => StartupMapper.map(startup));
  }

  public static async getStartupById(
    id: string | number,
    ignoreClient?: boolean
  ): Promise<Startup> {
    const response = ignoreClient
      ? await axios.get<StartupDTO>(`/api/startups/${id}`, {
          params: { ignoreClient },
        })
      : await axios.get<StartupDTO>(`/api/startups/${id}`);
    return StartupMapper.map(response.data);
  }

  public static async updateStartup(startup: Partial<Startup>): Promise<void> {
    await axios.put<void>(`/api/startups/${startup.id}`, startup);
  }

  public static async createStartup(
    startup: CreateStartupDTO
  ): Promise<number> {
    const { data } = await axios.post<{ id: number }>("/api/startups", {
      ...startup,
      dateFounded: startup.dateFounded ? new Date(startup.dateFounded) : null,
    });
    return data.id;
  }

  public static async getStartupsByProjectId(
    projectId: number
  ): Promise<Startup[]> {
    const response = await axios.get<StartupDTO[]>(
      `/api/startups/project?id=${projectId}`
    );
    return response.data.map((startup) => StartupMapper.map(startup));
  }

  public static async deleteStartup(startupId: number): Promise<void> {
    await axios.delete<void>(`/api/startups/${startupId}`);
  }

  public static async importStartupFromPitchbook(
    pitchbookId: string
  ): Promise<Startup> {
    const response = await axios.post<Startup>(`/api/startups/${pitchbookId}`);
    return response.data;
  }

  public static async getStartupOnePager(
    id: string | number,
    fileName: string,
    style: string
  ): Promise<void> {
    const { data } = await axios.get(`/api/startups/${id}/one-pager`, {
      params: { style: style },
    });

    const content = new Uint8Array(data.data);
    const fileBlob = new Blob([content], {
      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    });
    const downloadURL = window.URL.createObjectURL(fileBlob);

    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = downloadURL;
    tempLink.setAttribute(
      "download",
      `${fileName}${style === "Generic" ? ".pptx" : ".pdf"}`
    );

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
  }

  public static async syncStartup(id: number, pbId?: string): Promise<Startup> {
    const response = await axios.put<StartupDTO>(`/api/startups/${id}/sync`, {
      pbId,
    });
    return StartupMapper.map(response.data);
  }

  public static async deleteStartupPbLink(id: number): Promise<void> {
    await axios.delete<void>(`/api/startups/${id}/pitchbook`);
  }

  public static async getSalesforceStartupInfo(
    id: string
  ): Promise<StartupSalesforce> {
    const response = await axios.get(`/api/startups/${id}/salesforce`);
    return response.data;
  }

  public static async updateSalesforceStatusComment(
    id: string,
    comment: string
  ): Promise<void> {
    await axios.put(`/api/startups/${id}/salesforce`, {
      statusComment: comment,
    });
  }

  public static async getSimilarStartups(id: string): Promise<Startup[]> {
    const response = await axios.get(`/api/startups/${id}/similar-startups`);
    return response.data.map((competitor: StartupDTO) =>
      StartupMapper.map(competitor)
    );
  }
}
