import {
  Box,
  Button,
  Typography,
  styled,
  Badge,
  Skeleton,
  Stack,
  Chip,
  IconButton,
  Container,
  Tooltip,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import {
  FunnelStage,
  Project,
  ProjectFilterableProperties,
  ProjectStages,
} from "../../Types/Project";
import { ProjectKanbanFilter } from "./ProjectKanbanFilter";
import Fuse from "fuse.js";
import { UserContext } from "../../Context/UserContext";
import { areObjectsDeepEqual, capitalizeFirstLetter } from "../../utils";
import theme from "../../theme";
import ProjectkanbanTopHeader from "./ProjectKanbanHeader";
import MemoizedProjectCardsByStage from "./MemoizedProjectCardsByStage";
import { funnelStages } from "../../Constants/FunnelStages";
import { LeadProject } from "../../Types/LeadProject";
import CreateProjectLeadModal from "./CreateLeadProjectModal";
import { MemoizedLeadProjectCards } from "./MemoizedLeadProjectCards";
import useRoles from "../../Hooks/useRoles";
import useProjectKanbanData from "../../Hooks/useProjectKanbanData";
import { Close } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KANBAN_STATUS_FILTER_TOOLTIP } from "../../Constants/TooltipText";

const CHIPS_CONTAINER_INITIAL_HEIGHT = 40;

const getNumberOfSkeletonCardsForStage = (stage: FunnelStage) => {
  switch (stage) {
    case "adopt":
      return 3;
    default:
      return 4;
  }
};

const KanbanContentWrapper = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: `repeat(6, minmax(0px, 1fr))`,
  gap: theme.spacing(3),
  padding: theme.spacing(3, 0, 5),
  backgroundColor: "background.default",
  position: "sticky",
}));

const StageColumnWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: theme.spacing(2),
  gap: theme.spacing(1),
}));

const NumberOfCardsBadge = styled(Badge)(({ theme }) => ({
  justifyContent: "center",
  "& .MuiBadge-badge": {
    minWidth: "32px",
    padding: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.surface.primary.invert,
    color: theme.palette.text.primaryInvert.highEmphasis,
    fontVariant: theme.typography.body2,
    transform: "scale(1) translate(0, -50%)",
  },
}));

const FilterDisplay = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  padding: theme.spacing(2, 0),
  borderBottom: `1px solid ${theme.palette.borderOutline.main}`,
}));

const FilterChipsWrapper = styled(Container, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $showAll }: { $showAll: boolean }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: theme.spacing(1),
  height: $showAll ? "calc-size(auto)" : CHIPS_CONTAINER_INITIAL_HEIGHT,
  overflow: "hidden",
  transition: "height 0.5s",
  padding: theme.spacing(0.25),
}));

const StyledChip = styled(Chip)(() => ({
  backgroundColor: "white",
  maxWidth: "200px",
}));

function ProjectKanban(): ReactElement {
  const { isExternalUser, ventureClientId } = useRoles();
  const user = useContext(UserContext);

  const {
    projects,
    leadProjects,
    ventureClientSelectOptions,
    projectOwnersSelectOptions,
    businessUnitsSelectOptions,
    programManagersSelectOptions,
    focusAreasSelectOptions,
    isLoading,
    refreshProjectData,
  } = useProjectKanbanData(user || null, isExternalUser, ventureClientId);

  const [createLeadModalOpen, setCreateLeadModalOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [projectsByStage, setProjectsByStage] = useState<ProjectStages>(
    {} as ProjectStages
  );

  const [displayedLeadProjects, setDisplayedLeadProjects] =
    useState<LeadProject[]>(leadProjects);
  const [isStickied, setIsStickied] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreVisibility, setViewMoreVisibility] = useState(false);
  const [hiddenChipsCount, setHiddenChipsCount] = useState(0);

  const filterChipsRef = useRef<HTMLDivElement>(null);

  const defaultFilters = JSON.stringify({
    status: ["active"],
    projectOwners: !isExternalUser ? [user?.id] : undefined,
    startupIntelligence: !isExternalUser ? [user?.id] : undefined,
    ventureClients: isExternalUser ? [ventureClientId] : [],
  });

  const filterOptions = JSON.parse(
    localStorage.getItem("projectFilters") || defaultFilters
  );

  if (JSON.parse(localStorage.getItem("projectFilters") || "{}")) {
    localStorage.setItem("projectFilters", JSON.stringify(filterOptions));
  }

  useEffect(() => {
    const updateScrollability = () => {
      const chipsContainer = filterChipsRef.current;

      if (chipsContainer) {
        const isSingleRow =
          chipsContainer.scrollHeight <= CHIPS_CONTAINER_INITIAL_HEIGHT;
        setViewMoreVisibility(!isSingleRow);

        const chipElements = Array.from(chipsContainer.children);
        const containerWidth = chipsContainer.clientWidth;
        let rowWidth = 0;
        let visibleChipsCount = 0;

        for (const chip of chipElements) {
          const lastChip = chip === chipElements[chipElements.length - 1];
          const chipWidth = chip.clientWidth + (lastChip ? 0 : 8);
          if (rowWidth + chipWidth > containerWidth) {
            rowWidth = 0; // Start a new row
            if (
              visibleChipsCount * chip.clientHeight >
              CHIPS_CONTAINER_INITIAL_HEIGHT
            )
              break; // If adding this row exceeds the container height, stop
          }
          rowWidth += chipWidth;
          visibleChipsCount++;
        }
        setHiddenChipsCount(chipElements.length - visibleChipsCount);
      }
    };

    updateScrollability();

    window.addEventListener("resize", updateScrollability);

    return () => {
      window.removeEventListener("resize", updateScrollability);
    };
  }, [filterOptions]);

  useEffect(() => {
    projectStages.current && observer.observe(projectStages.current);
    return () => {
      projectStages.current && observer.unobserve(projectStages.current);
    };
  }, [projectsByStage]);

  useEffect(() => {
    const projectsToShow =
      searchValue !== ""
        ? (fuse.search(searchValue).map((result) => result.item) as Project[])
        : projects;

    const leadProjectsToShow =
      searchValue !== ""
        ? (fuseLeadProject
            .search(searchValue)
            .map((result) => result.item) as LeadProject[])
        : leadProjects;

    setProjectsByStage(
      // eslint-disable-next-line
      projectsToShow?.reduce((mapper: any, project: Project) => {
        (mapper[project["funnelStage"]] =
          (mapper[project["funnelStage"]] as Project[]) || []).push(project);
        return mapper;
      }, {}) as ProjectStages
    );

    setDisplayedLeadProjects(leadProjectsToShow);
  }, [projects, leadProjects, searchValue]);

  const fuse = new Fuse(projects, {
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.25,
    keys: ["name", "opportunities.startup.name"],
  });

  const fuseLeadProject = new Fuse(leadProjects, {
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.25,
    keys: ["name", "opportunities.startup.name"],
  });

  const kanbanTopHeader = useRef<HTMLDivElement>(null);
  const kanbanBottomHeader = useRef<HTMLDivElement>(null);
  const projectStages = useRef<HTMLDivElement>(null);

  const observer = new IntersectionObserver(
    ([e]) => {
      if (e.isIntersecting) {
        setIsStickied(true);
      } else {
        setIsStickied(false);
      }
    },
    {
      threshold: 0,
      rootMargin: `0px 0px ${
        (kanbanTopHeader.current?.getBoundingClientRect().height || 0) -
        window.innerHeight +
        ((kanbanBottomHeader.current?.getBoundingClientRect().height || 0) -
          parseInt(theme.spacing(12)))
      }px 0px`,
    }
  );

  const toggleViewMore = () => {
    setViewMore((prev) => !prev);
  };

  const handleModalOpen = () => {
    setCreateLeadModalOpen(true);
  };

  const handleModalClose = () => {
    setCreateLeadModalOpen(false);
  };

  const handleDeleteChipFilter = (
    filterName: keyof ProjectFilterableProperties,
    value: number
  ) => {
    const updatedOptions = { ...filterOptions };

    if (filterName !== "bookmarked") {
      updatedOptions[filterName] = (
        updatedOptions[filterName] as number[]
      )?.filter((_, index) => index != value);
    } else {
      updatedOptions.bookmarked = value;
    }

    if (filterName === "projectOwners") {
      if (updatedOptions.projectOwners) {
        updatedOptions.startupIntelligence = [...updatedOptions.projectOwners];
      }
    }

    localStorage.setItem("projectFilters", JSON.stringify(updatedOptions));
    refreshProjectData();
  };

  const handleDeleteStatusFilter = (status: string) => {
    const updatedOptions = {
      ...filterOptions,
      status: filterOptions.status.filter((s: string) => s !== status),
    };
    localStorage.setItem("projectFilters", JSON.stringify(updatedOptions));
    refreshProjectData();
  };

  const resetFilters = () => {
    localStorage.setItem("projectFilters", defaultFilters);
    setViewMore(false);
    refreshProjectData();
  };

  const disableReset = areObjectsDeepEqual(
    filterOptions,
    JSON.parse(defaultFilters)
  );
  const disableStatusChip = filterOptions.status.length < 2;

  return (
    <>
      <ProjectkanbanTopHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setFilterDrawerOpen={setFilterDrawerOpen}
        topHeaderRef={kanbanTopHeader}
        bottomHeaderRef={kanbanBottomHeader}
        isStickied={isStickied}
      />
      <Stack
        sx={{
          boxShadow: isStickied ? theme.boxShadows[3] : "none",
          transition: "all 0.5s",
          backgroundColor: theme.palette.background.default,
        }}
        position="sticky"
        top={
          kanbanTopHeader.current && kanbanBottomHeader.current
            ? kanbanTopHeader.current?.getBoundingClientRect().height +
              kanbanBottomHeader.current?.getBoundingClientRect().height -
              parseInt(theme.spacing(15))
            : 0
        }
        zIndex={3}
        ref={projectStages}
      >
        <FilterDisplay>
          <FilterChipsWrapper
            data-testid="kanban-filter-chips"
            $showAll={viewMore}
            ref={filterChipsRef}
          >
            <Button
              startIcon={<Close />}
              onClick={resetFilters}
              disabled={disableReset}
            >
              Reset
            </Button>
            {filterOptions.status.map((status: string) => (
              <Tooltip
                key={status}
                title={disableStatusChip ? KANBAN_STATUS_FILTER_TOOLTIP : null}
              >
                <span>
                  <StyledChip
                    disabled={disableStatusChip}
                    data-testid="statusChip"
                    size="medium"
                    label={capitalizeFirstLetter(status)}
                    onDelete={() => handleDeleteStatusFilter(status)}
                  />
                </span>
              </Tooltip>
            ))}
            {filterOptions.bookmarked && (
              <StyledChip
                data-testid="bookmarked"
                size="medium"
                label="Bookmarked"
                onDelete={() =>
                  handleDeleteChipFilter("bookmarked", filterOptions.bookmarked)
                }
              />
            )}
            {filterOptions.projectOwners?.length > 0 &&
              filterOptions.projectOwners.map(
                (ownerId: number, index: number) => {
                  const owner = projectOwnersSelectOptions.find(
                    (po) => po.id === ownerId
                  );
                  return (
                    owner && (
                      <StyledChip
                        key={index}
                        data-testid="projectOwnerChip"
                        size="medium"
                        label={owner ? owner.name : "Unknown Owner"}
                        onDelete={() =>
                          handleDeleteChipFilter("projectOwners", index)
                        }
                      />
                    )
                  );
                }
              )}
            {filterOptions.ventureClients?.length > 0 &&
              !isExternalUser &&
              filterOptions.ventureClients.map(
                (clientId: number, index: number) => {
                  const client = ventureClientSelectOptions.find(
                    (vc) => vc.id === clientId
                  );
                  return (
                    client && (
                      <StyledChip
                        key={index}
                        data-testid="ventureClientChip"
                        size="medium"
                        label={client ? client.name : "Unknown Client"}
                        onDelete={() =>
                          handleDeleteChipFilter("ventureClients", index)
                        }
                      />
                    )
                  );
                }
              )}
            {filterOptions.businessUnits?.length > 0 &&
              filterOptions.businessUnits.map(
                (unitId: number, index: number) => {
                  const unit = businessUnitsSelectOptions.find(
                    (bu) => bu.id === unitId
                  );
                  return (
                    unit && (
                      <StyledChip
                        key={index}
                        data-testid="businessUnitChip"
                        size="medium"
                        label={unit ? unit.name : "Unknown Unit"}
                        onDelete={() =>
                          handleDeleteChipFilter("businessUnits", index)
                        }
                      />
                    )
                  );
                }
              )}
            {filterOptions.programManagers?.length > 0 &&
              filterOptions.programManagers.map(
                (managerId: number, index: number) => {
                  const manager = programManagersSelectOptions.find(
                    (pm) => pm.id === managerId
                  );
                  return (
                    manager && (
                      <StyledChip
                        key={index}
                        data-testid="programManagerChip"
                        size="medium"
                        label={manager ? manager.name : "Unknown Manager"}
                        onDelete={() =>
                          handleDeleteChipFilter("programManagers", index)
                        }
                      />
                    )
                  );
                }
              )}
            {filterOptions.focusAreas?.length > 0 &&
              filterOptions.focusAreas.map((areaId: number, index: number) => {
                const area = focusAreasSelectOptions.find(
                  (fa) => fa.id === areaId
                );
                return (
                  area && (
                    <StyledChip
                      key={index}
                      data-testid="focusAreaChip"
                      size="medium"
                      label={area ? area.name : "Unknown Focus Area"}
                      onDelete={() =>
                        handleDeleteChipFilter("focusAreas", index)
                      }
                    />
                  )
                );
              })}
          </FilterChipsWrapper>
          {!viewMore && hiddenChipsCount > 0 && (
            <Chip
              variant="counter"
              color="secondary"
              label={`+${hiddenChipsCount}`}
            />
          )}
          {viewMoreVisibility && (
            <IconButton
              sx={{ alignSelf: "start" }}
              color="secondary"
              onClick={toggleViewMore}
            >
              {viewMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </FilterDisplay>
        <Container>
          <Box
            display="grid"
            gridTemplateColumns={`repeat(6, minmax(0px, 1fr))`}
            py={2}
            gap={3}
          >
            <StageColumn
              title="Leads"
              projects={displayedLeadProjects}
              isLoading={isLoading}
            />
            {funnelStages.map((stage) => (
              <StageColumn
                key={stage}
                title={capitalizeFirstLetter(stage)}
                projects={projectsByStage[stage]}
                isLoading={isLoading}
              />
            ))}
          </Box>
        </Container>
      </Stack>
      <Container>
        <KanbanContent
          isLoading={isLoading}
          projectsByStage={projectsByStage}
          leadProjects={displayedLeadProjects}
          refreshProjectData={refreshProjectData}
          handleModalOpen={handleModalOpen}
        />
      </Container>
      {createLeadModalOpen && (
        <CreateProjectLeadModal
          modalOpen={createLeadModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
      {filterDrawerOpen && (
        <ProjectKanbanFilter
          open={filterDrawerOpen}
          setOpen={setFilterDrawerOpen}
          refresh={refreshProjectData}
          filterSelection={{
            ventureClientSelectOptions: ventureClientSelectOptions,
            projectOwnersSelectOptions: projectOwnersSelectOptions,
            businessUnitsSelectOptions: businessUnitsSelectOptions,
            programManagersSelectOptions: programManagersSelectOptions,
            focusAreasSelectOptions: focusAreasSelectOptions,
          }}
        />
      )}
    </>
  );
}

export default ProjectKanban;

interface StageColumnProps {
  title: string;
  projects: Project[] | LeadProject[];
  isLoading: boolean;
}

const StageColumn = ({ title, projects, isLoading }: StageColumnProps) => (
  <Stack gap={2} data-testid={`${title}-column`}>
    <StageColumnWrapper data-testid="card-number-display">
      <Typography variant="h5">{title}</Typography>
      {isLoading ? (
        <Skeleton
          height={17}
          width={32}
          sx={{ borderRadius: theme.shape.radius.full }}
        />
      ) : (
        <NumberOfCardsBadge
          badgeContent={projects?.length || 0}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          max={999}
          showZero
        />
      )}
    </StageColumnWrapper>
  </Stack>
);

interface KanbanContentProps {
  isLoading: boolean;
  projectsByStage: ProjectStages;
  leadProjects: LeadProject[];
  refreshProjectData: (preventLoading?: boolean) => Promise<void>;
  handleModalOpen: () => void;
}

const KanbanContent = ({
  isLoading,
  projectsByStage,
  leadProjects,
  refreshProjectData,
  handleModalOpen,
}: KanbanContentProps) => (
  <KanbanContentWrapper>
    <Stack gap={2}>
      {isLoading ? (
        <>
          <Skeleton height={36} />
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} height={theme.spacing(17)} />
          ))}
        </>
      ) : (
        <>
          <Button
            variant="contained"
            data-testid="new-lead-button"
            onClick={handleModalOpen}
          >
            + Add Lead
          </Button>
          <MemoizedLeadProjectCards
            leadProjects={leadProjects}
            refreshLeadProjectData={refreshProjectData}
          />
        </>
      )}
    </Stack>
    {funnelStages.map((stage) => (
      <Stack key={stage} gap={2} data-testid={`${stage}ColumnCards`}>
        {isLoading ? (
          [...Array(getNumberOfSkeletonCardsForStage(stage))].map(
            (_, index) => <Skeleton key={index} height={theme.spacing(17)} />
          )
        ) : (
          <MemoizedProjectCardsByStage
            projects={projectsByStage[stage]}
            stage={stage}
            refreshProjectData={refreshProjectData}
          />
        )}
      </Stack>
    ))}
  </KanbanContentWrapper>
);
