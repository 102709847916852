import {
  Button,
  Chip,
  Paper,
  Stack,
  Typography,
  styled,
  Box,
} from "@mui/material";
import { Project } from "../../../../../Types/Project";
import {
  capitalizeFirstLetter,
  checkExternalUser,
  formatDate,
  getStatusColor,
} from "../../../../../utils";
import ContactName from "../../../../Contacts/ContactCard/ContactName";
import { ReactElement, useState } from "react";
import ProjectStatusOverview from "./ProjectStatusOverview";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import theme from "../../../../../theme";
import { ProjectHistory } from "../../../../../Types/ProjectHistory";
import EditProjectStatus from "./EditProjectStatus";
import CustomExpendableText from "../../../../UI/CustomExpendableText";
import ManageStatusComment from "./ManageStatusComment";
import {
  CallSplit,
  Check,
  East,
  Pause,
  PlayArrow,
  Stop,
  Stream,
} from "@mui/icons-material";
import parse from "html-react-parser";

const Container = styled(Stack)(() => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const IconWrapper = styled(Box)(() => ({
  padding: theme.spacing(1),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: theme.palette.surface.mediumEmphasis,
}));

const LinkParser = styled(Typography)(() => ({
  "& a": {
    color: theme.palette.text.action.main,
    ...theme.typography.subtitle2,
  },
}));

interface Props {
  project: Project;
  handleSave: () => void;
}

const ProjectStatus = (props: Props): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [manageCommentModal, setManageCommentModal] = useState(false);
  const [associatedModal, setAssociatedModal] = useState(false);

  const status = props.project.status;
  const onHoldDeadline = props.project.onHoldDeadline
    ? formatDate(new Date(props.project.onHoldDeadline))
    : null;
  const isOnHold = props.project.status === "on hold";
  const isArchived = props.project.status === "archived";
  const isOnHoldorArchived = isOnHold || isArchived;
  const reason =
    props.project.projectHistoryLatestChange[0]?.projectStatusChangeReason;

  const handleViewMore = () => {
    setModalOpen(true);
    setAssociatedModal(true);
  };

  const handleAddNewComment = () => {
    setManageCommentModal(true);
    setAssociatedModal(false);
  };

  const handleEditStatus = () => {
    setEditStatusModal(true);
    setAssociatedModal(false);
  };

  const renderHistoryItem = (history: ProjectHistory) => {
    const isExternalUser = checkExternalUser(history.user);
    switch (history.type) {
      case "Project Status Comment":
        return (
          <Stack gap={2} data-testid="status-comment-history">
            <ContactName
              isVentureAssociate={!isExternalUser}
              orientation="horizontal"
              name={history.user.name}
              title={formatDate(history.dateTriggered)}
            />
            <CustomExpendableText text={history.description} />
          </Stack>
        );

      case "Project Stage Change":
        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <East sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                The project has been moved to{" "}
                <b>{capitalizeFirstLetter(history.newValue || "")}</b> stage.
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Status Change":
        let icon;
        switch (history.newValue) {
          case "on hold":
            icon = (
              <IconWrapper>
                <Pause sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          case "archived":
            icon = (
              <IconWrapper>
                <Stop sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          case "adopted":
            icon = (
              <IconWrapper>
                <Check sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          default:
            icon = (
              <IconWrapper>
                <PlayArrow sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
        }

        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              {icon}
              <Typography>
                The project has been set to <b>{history.newValue}</b>.
              </Typography>
            </Box>

            {(isOnHoldorArchived || history.description) && (
              <Stack gap={1}>
                {isOnHoldorArchived && (
                  <Stack>
                    <Box display="flex" alignItems="start">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.mediumEmphasis}
                      >
                        Reason
                      </Typography>
                      &nbsp;
                      <Typography variant="body2">
                        {reason?.description || ""}
                      </Typography>
                    </Box>
                    {isOnHold && (
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color={theme.palette.text.mediumEmphasis}
                        >
                          On hold until
                        </Typography>
                        &nbsp;
                        <Typography variant="body2">
                          {onHoldDeadline}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                )}
                <CustomExpendableText text={history.description} />
              </Stack>
            )}
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Created":
        return (
          <Container data-testid="project-created-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <Stream sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                The project has been <b>launched</b>.
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>

              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Split":
        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <CallSplit
                  sx={{
                    color: "white",
                    fontSize: "1rem",
                    transform: "rotate(90deg)",
                  }}
                />
              </IconWrapper>
              <LinkParser>{parse(history.description)}</LinkParser>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Paper sx={{ p: 3 }} data-testid="project-updates-section">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" mb={1}>
            Project Updates
          </Typography>
          <AddCommentOutlinedIcon
            color="secondary"
            fontSize="small"
            sx={{
              cursor: "pointer",
            }}
            onClick={handleAddNewComment}
          />
        </Box>

        <Stack gap={3}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Chip
              label={capitalizeFirstLetter(status)}
              variant="outlined"
              size="medium"
              sx={{
                borderColor: getStatusColor(status),
                color: getStatusColor(status),
              }}
            />
            <EditOutlinedIcon
              sx={{
                fontSize: "1rem",
                color: theme.palette.text.mediumEmphasis,
                cursor: "pointer",
              }}
              onClick={handleEditStatus}
            />
          </Box>
          {renderHistoryItem(props.project.projectHistoryLatestChange[0])}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleViewMore}
          >
            View More
          </Button>
        </Stack>
      </Paper>
      {editStatusModal && (
        <EditProjectStatus
          projectId={props.project.id}
          statusComment={props.project.statusComment}
          status={props.project.status}
          statusReason={reason}
          onHoldDeadline={props.project.onHoldDeadline}
          modalOpen={editStatusModal}
          setModalOpen={setEditStatusModal}
          funnelStage={props.project.funnelStage}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setProjectStatusOverviewModal={setModalOpen}
        />
      )}
      {modalOpen && (
        <ProjectStatusOverview
          projectId={props.project.id}
          status={status}
          onHoldDeadline={props.project.onHoldDeadline}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleSave={props.handleSave}
          setEditStatusModal={setEditStatusModal}
          setManageCommentModal={setManageCommentModal}
        />
      )}
      {manageCommentModal && (
        <ManageStatusComment
          modalOpen={manageCommentModal}
          setModalOpen={setManageCommentModal}
          projectId={props.project.id}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setProjectStatusOverviewModal={setModalOpen}
        />
      )}
    </>
  );
};

export default ProjectStatus;
